<template>
  <v-row no-gutters>
    <v-col class="subtitle-2 mr-2" cols="1">
      {{title}}:
    </v-col>

    <v-col v-if="title === 'Link'">
      <a target="_blank" :href="value">{{value}}</a>
    </v-col>
    <v-col v-else-if="title === 'Tags'">
      <v-chip x-small v-for="item in value" :key="item">
        {{item}}
      </v-chip>

    </v-col>
    <v-col v-else justify-self="end" cols="auto" v-html="value" class="mr-2">
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'DetailItem',
  props: ['value', 'title'],
};
</script>
<style scoped>
  ::v-deep em {
    background: lemonchiffon;
    text-decoration: underline;
  }
</style>
