<template>
  <v-card>
    <v-card-title>Top matches</v-card-title>
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Adverse matches</th>
            <th class="text-left">Media matches</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in value" :key="item.name">
            <td>{{item.name }}</td>
            <td class="text-right">{{item.adverse}}</td>
            <td class="text-right">{{item.nonAdverse}}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TopMatches',
  props: {
    value: Array,
  },
};
</script>

<style scoped>

</style>
