<template>
  <v-container fluid>
    <v-row dense no-gutters>
      <v-text-field
        label="Place enter search here..."
        v-model="queryString"
        @input="doThrottlingSearch"
        append-outer-icon="search"
        clearable
        autofocus
        solo
        full-width
      >
        <template v-slot:append-outer>
          <v-icon large @click="doSearch" class="pr-2">
            search
          </v-icon>
        </template>
      </v-text-field>
    </v-row>
    <v-row v-if="this.buckets.length > 0">
      <TopMatches :value="this.buckets"/>
    </v-row>
    <v-row dense no-gutters>
      <v-col>
        <v-data-table
          :items="this.queryResult"
          :headers="headers"
          item-key="_id"
        >

          <template v-slot:item="{ headers, item }">
            <tr>
              <td>
                {{item._score}}
              </td>

              <td>
                {{item._source.fullname}}
              </td>
              <td colspan="7">
                <DetailItem v-model="item._source.title" title="Title"/>
                <DetailItem v-model="item._source.pubDate" title="Date"/>
                <DetailItem v-model="item._source.topic" title="Topic"/>
                <DetailItem v-model="item._source.tags" title="Tags"/>
                <DetailItem v-model="item.highlight.sentence" title="Sentence"/>
                <DetailItem v-model="item._source.description" title="Description"/>
                <DetailItem v-model="item._source.link" title="Link"/>
              </td>
            </tr>

          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { debounce } from 'lodash';
import DetailItem from '@/components/admedia/DetailItem';
import TopMatches from '@/views/admedia/TopMatches';
import apiClient from '../../services/apiClient';

export default {
  components: { TopMatches, DetailItem },
  data() {
    return {
      queryString: null,
      buckets: [],
      queryResult: [],
      headers: [
        // {
        //   text: 'Id',
        //   value: '_id',
        // },
        {
          text: 'Score',
          value: '_score',
        },
        {
          text: 'Full name',
          value: '_source.fullname',
        },
        {
          text: 'Details',
        },
      ],
    };
  },
  methods: {
    async doSearch() {
      try {
        const result = await apiClient.adverseSearch(this.queryString);
        this.queryResult = result?.data?.hits?.hits;
        this.buckets = result?.data?.buckets;
      } catch (e) {
        console.error(e);
      }
    },
    // eslint-disable-next-line func-names
    doThrottlingSearch: debounce(function () { this.doSearch(); }, 1000),

  },
};
</script>

<style scoped>

</style>
